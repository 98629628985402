// navigation
export const NAVIGATION_SET_REDIRECT = "navigation_set_redirect";
export const NAVIGATION_RESET_REDIRECT = "navigation_reset_redirect";
export const NAVIGATION_SET_LOCATION = "navigation_set_location";

// user
export const LOGIN_LOADING = "login_loading";
export const LOGIN_SUCCESS = "login_success";
export const LOGIN_ERROR = "login_error";
export const LOGOUT = "logout";
export const AUTH_SET_NEXT = "auth_set_next";
export const AUTH_CHECK_COOKIE_SUCCESS = "auth_check_cookie_success";
export const AUTH_CHECK_COOKIE_ERROR = "auth_check_cookie_error";

// dashboard
export const DASHBOARD_LOADING = "dashboard_loading";
export const DASHBOARD_FETCH_SUCCESS = "dashboard_fetch_success";
export const DASHBOARD_FETCH_ERROR = "dashboard_fetch_error";
export const DASHBOARD_RESET = "dashboard_reset";
export const DASHBOARD_UPDATE_WS = "dashboard_update_ws";
export const DASHBOARD_WS_CLOSED = "dashboard_ws_closed";
export const DASHBOARD_WS_OPEN = "dashboard_ws_open";
export const SENSOR_MODE = "sensor";

// plant
export const PLANT_LOADING = "plant_loading";
export const PLANT_FETCH_SUCCESS = "plant_fetch_success";
export const PLANT_FETCH_ERROR = "plant_fetch_error";
export const PLANT_RESET = "plant_reset";
export const PLANT_UPDATE_WS = "plant_update_ws";
export const PLANT_WS_CLOSED = "plant_ws_closed";
export const PLANT_WS_OPEN = "plant_ws_open";
export const PLANT_FIRST_SENSOR_ALARM = "plant_first_sensor_alarm";
export const PLANT_SECOND_SENSOR_LIGHT = "plant_second_sensor_light";
export const PLANT_MODE = "plant_mode";

export const PLANT_SIREN_ENABLE = "plant_siren_enable";
export const PLANT_SIREN_DISABLE = "plant_siren_disable";

// sensor
export const SENSOR_LOADING = "sensor_loading";
export const SENSOR_FETCH_SUCCESS = "sensor_fetch_success";
export const SENSOR_FETCH_ERROR = "sensor_fetch_error";
export const SENSOR_UPDATE_WS = "sensor_update_ws";
export const SENSOR_WS_CLOSED = "sensor_ws_closed";
export const SENSOR_WS_OPEN = "sensor_ws_open";
export const IMAGE_SENSOR_FETCH_SUCCESS = "image_sensor_fetch_success";
export const IMAGE_SENSOR_FETCH_ERROR = "image_sensor_fetch_error";
export const IMAGE_SENSOR_UPDATE_WS = "image_sensor_update_ws";
export const ALARM_SENSOR_FETCH_SUCCESS = "alarm_sensor_fetch_success";
export const ALARM_SENSOR_FETCH_ERROR = "alarm_sensor_fetch_error";
export const ALARM_SENSOR_UPDATE_WS = "alarm_sensor_update_ws";
export const ALL_SENSORS_FETCH_SUCCESS = "all_sensors_fetch_success";
export const ALL_SENSORS_FETCH_ERROR = "all_sensors_fetch_error";
export const ALL_PLANTS_FETCH_SUCCESS = "all_plants_fetch_success";
export const ALL_PLANTS_FETCH_ERROR = "all_plants_fetch_error";
export const ALL_PLANTS_FETCH_LOADING = "all_plants_fetch_loading";

// alarm
export const ALARM_LOADING = "alarm_loading";
export const ALARM_FETCH_SUCCESS = "alarm_fetch_success";
export const ALARM_APPEND_SUCCESS = "alarm_append_success";
export const ALARM_FETCH_ERROR = "alarm_fetch_error";
export const ALARM_RESET = "alarm_reset";
export const ALARM_UPDATE_WS = "alarm_update_ws";
export const ALARM_WS_CLOSED = "alarm_ws_closed";
export const ALARM_WS_OPEN = "alarm_ws_open";
export const ALARM_RESET_NEXT_PAGE = "alarm_reset_next_page";

// image
export const IMAGE_LOADING = "image_loading";
export const IMAGE_FETCH_SUCCESS = "image_fetch_success";
export const IMAGE_APPEND_SUCCESS = "image_append_success";
export const IMAGE_FETCH_ERROR = "image_fetch_error";
export const IMAGE_RESET = "image_reset";
export const IMAGE_UPDATE_WS = "image_update_ws";
export const IMAGE_WS_CLOSED = "image_ws_closed";
export const IMAGE_WS_OPEN = "image_ws_open";

// notifications
export const NOTIFICATIONS_LOADING = "notifications_loading";
export const NOTIFICATIONS_FETCH_SUCCESS = "notifications_fetch_success";
export const NOTIFICATIONS_FETCH_ERROR = "notifications_fetch_error";
export const NOTIFICATIONS_APPEND_SUCCESS = "notifications_append_success";
export const NOTIFICATIONS_APPEND_ERROR = "notifications_append_error";
export const NOTIFICATIONS_READ_LOADING = "notifications_read_loading";
export const NOTIFICATIONS_READ_SUCCESS = "notifications_read_success";
export const NOTIFICATIONS_READ_ERROR = "notifications_read_error";
export const NEW_NOTIFICATION_RECEIVED = "new_notification_received";
export const NOTIFICATIONS_SET_FETCH_PARAMS = "notifications_set_fetch_params";
export const NOTIFICATIONS_CLEAR = "notifications_clear";
export const CURRENT_NOTIFICATION_FETCH_SUCCESS =
  "current_notification_fetch_success";
export const CURRENT_NOTIFICATION_FETCH_ERROR =
  "current_notification_fetch_error";
export const CURRENT_NOTIFICATION_RESET = "current_notification_reset";
export const CURRENT_NOTIFICATION_STATUS_FETCH_SUCCESS =
  "current_notification_status_fetch_success";
export const CURRENT_NOTIFICATION_STATUS_FETCH_ERROR =
  "current_notification_status_fetch_error";
export const CURRENT_NOTIFICATION_STATUS_RESET =
  "current_notification_status_reset";

// commands
export const COMMAND_WAITING = "command_waiting";
export const COMMAND_SUCCESS = "command_success";
export const COMMAND_ERROR = "command_error";
export const COMMAND_TARGET_ERROR = "command_target_error";
export const COMMAND_RESET = "command_reset";

// settings
export const DAYS_FETCH_SUCCESS = "days_fetch_success";
export const DAYS_FETCH_ERROR = "days_fetch_error";
export const TIME_FILTERS_FETCH_SUCCESS = "time_filters_fetch_success";
export const TIME_FILTERS_FETCH_ERROR = "time_filters_fetch_error";
export const RULES_FETCH_SUCCESS = "rules_fetch_success";
export const RULES_FETCH_ERROR = "rules_fetch_error";
export const RULE_CHANGED_SUCCESS = "rule_changed_success"; // in-app
export const RULES_UPDATE_SUCCESS = "rules_changed_success"; // api req
export const RULES_UPDATE_ERROR = "rules_changed_error"; // api req
export const RULE_DELETE_SUCCESS = "rule_delete_success";
export const RULE_DELETE_ERROR = "rule_delete_error";
export const RULE_CREATE_SUCCESS = "rule_create_success";
export const RULE_CREATE_ERROR = "rule_create_error";
export const EVENTS_FETCH_SUCCESS = "events_fetch_success";
export const EVENTS_FETCH_ERROR = "events_fetch_error";

// cookie terms
export const COOKIE_BANNER_HIDE = "cookie_banner_hide";

// Select types
export const HOME = "home";
export const CONFIGURATION = "configuration";

// configuration
export const SENSOR_CONFIG_FETCH_ERROR = "sensor_config_fetch_error";
export const SENSOR_CONFIG_FETCH_SUCCESS = "sensor_config_fetch_success";
export const SENSOR_CONFIG_LOADING = "sensor_config_loading";
export const SENSOR_CONFIG_SAVE_SUCCESS = "sensor_config_save_success";
