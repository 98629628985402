import React, { Component } from "react";
import { connect } from "react-redux";

import { ErrorMessagePopup } from "../../base_components";
import Command from "../command";
import PlantSettingsButton from "../plant_settings/PlantSettingsButton";
import { resetCommandStatus } from "./actions";

import colors from "../../../values/colors";
import { Errors } from "../../../values/strings";
import { getBorderText3 } from "../../../utils/utils";
import { types as CommandTypes } from "../../../values/commands";
import urls from "../../../values/urls";

class CommandList extends Component {
  renderErrorPopup() {
    const { error } = this.props.command;

    if (!error) return null;

    const text = error.response
      ? Errors["ERROR_" + error.response.status]
      : Errors.ERROR_CONN_REFUSED;

    return (
      <ErrorMessagePopup
        idModal={this.idErrorPopupModal}
        text={text}
        onHide={() => this.props.resetCommandStatus()}
        show
      />
    );
  }

  renderTitle() {
    const { title, idPlant } = this.props;

    if (!title) return null;

    const titleEl = (
      <h1 className="page-title" style={_style.title}>
        {title}
      </h1>
    );

    return (
      <div style={_style.titleContainer}>
        {titleEl}
        {!!idPlant && <PlantSettingsButton plantId={idPlant} />}
      </div>
    );
  }

  renderStaffCommand(/* type, href */) {
    /*
    const { user, idPlant, idSensor } = this.props;
    return user.user.is_staff && !idPlant && !!idSensor ? (
      <Command idSensor={idSensor} type={type} href={href} />
    ) : null;
    */

    return null;
  }

  renderCameraCommand() {
    const { idSensor } = this.props;
    return !!idSensor ? (
      <Command
        idSensor={idSensor}
        type={CommandTypes.CAMERA}
        href={`${urls.DASHBOARD_BASE}/sensors/camera-feed/${idSensor}/`}
      />
    ) : null;
  }

  renderControlCommands() {
    const { idPlant, idSensor, initialChecked = {}, plantsSiren } = this.props;
    const sirenDisabled =
      this.props.sirenDisabled || (!!idPlant && plantsSiren[idPlant]);

    let result = [
      <Command
        switchButton
        idSensor={idSensor}
        idPlant={idPlant}
        type={CommandTypes.IDLE}
        initialChecked={!initialChecked[CommandTypes.IDLE]}
        confirmDisable
        key={idPlant + "_1"}
      />,

      <Command
        switchButton
        idSensor={idSensor}
        idPlant={idPlant}
        type={CommandTypes.SIREN}
        disabled={sirenDisabled}
        // default to false
        // initialChecked={initialChecked[CommandTypes.SIREN]}
        waitForNewStates
        key={idPlant + "_2"}
      />,

      <Command
        switchButton
        idSensor={idSensor}
        idPlant={idPlant}
        type={CommandTypes.LIGHT}
        // default to false
        // initialChecked={initialChecked[CommandTypes.LIGHT]}
        waitForNewStates
        key={idPlant + "_3"}
      />,

      <Command
        switchButton
        idSensor={idSensor}
        idPlant={idPlant}
        type={CommandTypes.LION_KING}
        initialChecked={initialChecked[CommandTypes.LION_KING]}
        confirmDisable
        key={idPlant + "_4"}
      />,
    ];

    return result;
  }

  render() {
    return (
      <div style={_style.container}>
        {this.renderTitle()}

        <div className="dashboard-track-commands" style={_style.list}>
          {this.renderCameraCommand()}

          {this.renderStaffCommand(CommandTypes.RESTART)}

          {this.renderControlCommands()}
        </div>

        {this.renderErrorPopup()}
      </div>
    );
  }
}

const mapStateToProps = ({ command, user, plantsSiren }) => {
  return { command, user, plantsSiren };
};

export default connect(mapStateToProps, { resetCommandStatus })(CommandList);

const _style = {
  container: {
    margin: "0 0 1rem 0",
    padding: ".8rem 1rem",
    border: "1.5px solid",
    backgroundColor: colors.secondaryLight,
    borderColor: colors.secondaryDark,
    borderRadius: 16,
  },
  list: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: see style.css
    alignItems: "center",
  },
  title: {
    display: "block",
    textAlign: "center",
    color: colors.creamLight,
    textShadow: getBorderText3(colors.disabledDark),
    width: "100%",
    paddingLeft: 52,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: ".5rem 0 1.5rem 0",
  },
  settingsButton: {
    justifySelf: "flex-end",
  },
};
