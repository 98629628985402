export const types = {
  IDLE: "idle",
  RESTART: "restart",
  LIGHT: "light",
  SIREN: "siren",
  CAMERA: "camera-feed",
  PLANT_MODE: "plant_mode",
  SENSOR_SWITCH: "sensor_switch",
  LION_KING: "switch_lion_king_mode",
  SIREN_DISABLE: "disable_sir",
};

export const command_list = {
  IDLE: "switch_idle_mode",
  RESTART: "reboot",
  LIGHT: "switch_semaphore_mode",
  SIREN: "switch_siren_mode",
  PLANT_MODE: "switch_plant_mode",
  SENSOR_SWITCH_MODE: "sensor_switch_mode",
  POWER: "switch_lion_king_mode",
};

export const command_value = {
  BLINKING: 2,
  ON: 1,
  OFF: 0,
  UNKNOWN: -1,
};
