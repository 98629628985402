import { getPlantInitialCheckedObject } from "../utils/commands_utils";
import {
  PLANT_SIREN_ENABLE,
  PLANT_SIREN_DISABLE,
  DASHBOARD_FETCH_SUCCESS,
} from "../values/action_types";
import { types as CommandTypes } from "../values/commands";

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  const { plant_id, type, payload } = action;

  switch (type) {
    case DASHBOARD_FETCH_SUCCESS: {
      // payload = plant[]
      return payload.reduce((total, plant) => {
        const values = getPlantInitialCheckedObject(plant);
        return {
          ...total,
          [plant.id]: values[CommandTypes.SIREN_DISABLE],
        };
      }, state);
    }
    case PLANT_SIREN_ENABLE:
      return {
        ...state,
        [plant_id]: false,
      };
    case PLANT_SIREN_DISABLE:
      return {
        ...state,
        [plant_id]: true,
      };
    default:
      return state;
  }
}
