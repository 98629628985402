import axios from "axios";
import { getApiPath } from "../../../utils/actions_utils";
import { COMMAND_APP } from "../../../values/api_apps";
import { reqOptions } from "../../../utils/auth";
import {
  PLANT_SIREN_DISABLE,
  PLANT_SIREN_ENABLE,
} from "../../../values/action_types";

export const sendSirenDisablingRequest =
  (idPlant, disable, onSuccess, onError) => (dispatch) => {
    const url = getApiPath(
      COMMAND_APP,
      `/plant/${idPlant}/switch_siren_status?switch=${disable ? "on" : "off"}`
    );

    const action = {
      type: disable ? PLANT_SIREN_DISABLE : PLANT_SIREN_ENABLE,
      plant_id: idPlant,
    };

    return axios(url, reqOptions)
      .then((res) => {
        if (onSuccess) onSuccess(res);
        dispatch(action);
      })
      .catch(onError);
  };
