import React, { Component } from "react";
import { translate } from "react-translate";
import { connect } from "react-redux";

import { ConfirmModal, Icon, Loader, Modal } from "../../base_components";

import { types as CommandTypes } from "../../../values/commands";
import colors from "../../../values/colors";
import {
  getCommandByType,
  getIconNameByType,
} from "../../../utils/commands_utils";
import { getSensorStatus } from "../../../utils/sensor_utils";
import { log } from "../../../utils/utils";
import { sendPlantCommand, sendSensorCommand } from "./actions";
import {
  fetchSensorStatus,
  updateCurrentSensorWS,
  sensorDetailWSClosed,
} from "./actions";

import { SensorStatus } from "../../../values/statuses";

const SWITCH_CLASS = "switch";
const SWITCH_PRE_STYLE_PROP = "switch_";
const SWITCH_ACTIVE_BOX_SHADOW_PX = "0 0 0 1.5px ";

class Command extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mounted: false,
      loading: false,
      checked: !!props.initialChecked,
      newChecked: !!props.initialChecked,
      translateX: 0,
      showConfirmModal: false,
      showCommandInfoModal: false,
      canAutoToggle: true,
      status_verbose: getSensorStatus(props.sensor, props.type),
      status_ws_connected: true,
      status_ws_connecting: true,
    };

    this.canAutoToggleTimeout = null;
    this.commandString = getCommandByType(props.type);
    this.htmlID = `${this.commandString}_${props.idSensor || props.idPlant}`;
    // this.idErrorPopupModal = this.htmlID + '_error_popup_modal';
    this.idConfirmDisablePopupModal =
      this.htmlID + "_confirm_disable_popup_modal";
    this.idCommandInfoModal = this.htmlID + "_command_info_modal";

    this.calculateTranslateX = this.calculateTranslateX.bind(this);
  }

  customSetState(state, callback) {
    if (this.state.mounted) {
      this.setState(state, callback);
    }
  }

  componentDidMount() {
    this.setState({ mounted: true });
    this.calculateTranslateX();
    window.addEventListener("resize", this.calculateTranslateX);
    const { type } = this.props;

    // if sensors dashboard
    if (type == CommandTypes.SENSOR_SWITCH) {
      //this.setupStatus();

      this.interval = setInterval(() => {
        this.customSetState({ status_verbose: this.getStatusVerbose() });
      }, 1000);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // check if initial checked prop changed (delay due to api requests)
    const checked = !!this.props.initialChecked;
    if (!!prevProps.initialChecked !== checked) {
      if (this.state.canAutoToggle) {
        log("Can auto toggle, new checked: " + checked);
        this.customSetState({ checked });
      } else {
        log("Cannot auto toggle, new checked: " + checked);
        this.customSetState({ newChecked: checked });
      }
    }

    // component did mount
    if (!prevState.mounted && this.state.mounted) {
      this.calculateTranslateX();
    }
  }

  componentWillUnmount() {
    this.customSetState({ mounted: false });
    window.removeEventListener("resize", this.calculateTranslateX);
    clearInterval(this.interval);
  }

  getStatusVerbose() {
    const { sensor, type } = this.props;

    const status_verbose = getSensorStatus(sensor, type);

    // calculate again sensor status in 5 minutes (5 minutes - time_diff)
    if (status_verbose === SensorStatus.ALARM) {
      clearTimeout(this.alarm_timeout);
      this.alarm_timeout = setTimeout(() => {
        this.forceUpdate();
      }, 1000 * 60 * 5 - Date.now() + new Date(sensor.alarm_status.insert_date).getTime());
    }

    return status_verbose;
  }

  //    setupStatus() {
  //        this.websocket_status = null;
  //        const {sensor} = this.props;
  //
  //        this.setState({
  //            status_ws_connecting: true
  //        }, () => {
  //
  //            this.props.fetchSensorStatus(sensor.id);
  //
  //            const setWSState = (status_ws_connecting, status_ws_connected) => this.setState({
  //                status_ws_connecting,
  //                status_ws_connected
  //            });
  //
  //            const url = getWebSocketURL(SENSOR_APP, `/dashboard/sensor/${sensor.id}/`);
  //
  //            this.websocket_status = new WebSocket(url);
  //
  //            const {sensorDetailWSClosed} = this.props;
  //
  //            this.websocket_status.onopen = function () {
  //                setWSState(false, true);
  //                log('Sensor detail status websocket open');
  //            };
  //
  //            this.websocket_status.onmessage = ({data}) => {
  //                data = JSON.parse(data);
  //
  //                this.props.updateCurrentSensorWS(data);
  //                log('Sensor detail status websocket message received. Data: ', data);
  //            };
  //
  //            this.websocket_status.onclose = function () {
  //                log('Sensor detail status websocket closed');
  //                // sensorDetailWSClosed();
  //                setWSState(false, false);
  //            };
  //        });
  //    }

  calculateTranslateX() {
    const { switchButton } = this.props;

    if (switchButton) {
      const _command = $(`#${this.htmlID}`).find(".command"); // eslint-disable-line no-undef

      if (_command) {
        const translateX =
          // eslint-disable-next-line no-undef
          $(_command).parent().width() -
          $(_command).width() - // eslint-disable-line no-undef
          parseFloat($(_command).css("padding-left")) - // eslint-disable-line no-undef
          parseFloat($(_command).css("padding-right")); // eslint-disable-line no-undef

        if (translateX !== this.state.translateX && this.state.mounted)
          this.customSetState({ translateX });
      }
    }
  }

  getReqParams() {
    const { switchButton, type } = this.props;

    if (!switchButton) return {};

    const { checked } = this.state;
    const on = "on",
      off = "off";

    // for idle command, the behavior is the opposite to the other commands, so if the command switch is checked we
    // need to set {switch: on} and vice versa
    const choises = type === CommandTypes.IDLE ? [on, off] : [off, on];

    return { switch: checked ? choises[0] : choises[1] };
  }

  setLoading(loading) {
    this.customSetState({ loading });
  }

  setChecked(checked) {
    this.customSetState({ checked });
  }

  //----------------------------------------------switch--------------------------------------------------------------
  onSwitchClick() {
    const { confirmDisable, switchButton, href } = this.props;

    if (!this.state.loading && !href) {
      if (confirmDisable && switchButton && this.state.checked) {
        this.customSetState({ showConfirmModal: true });
      } else {
        this.toggleSwitch();
      }
    }
  }

  toggleSwitch() {
    if (!this.props.href) {
      const { loading } = this.state;
      const { idSensor, idPlant, type, switchButton, waitForNewStates } =
        this.props;

      if (switchButton && loading) return;

      const req_params = this.getReqParams();
      const cmd_type = getCommandByType(type);

      const _onEnd = () => {
        this.customSetState({
          loading: false,
          showConfirmModal: false,
          showCommandInfoModal: true,
        });

        setTimeout(
          () => this.customSetState({ showCommandInfoModal: false }),
          1000
        );
      };

      let setLoading = () => {};
      let onSuccess = () => {};
      let onError = () => {};
      let onEnd = () => {};

      if (!waitForNewStates) {
        setLoading = () => this.setLoading(true);
        onSuccess = () => this.setChecked(!this.state.checked);
        onError = () =>
          this.customSetState({
            loading: false,
            showConfirmModal: false,
          });
        onEnd = () => _onEnd();
      }

      if (idSensor !== undefined && idSensor !== null) {
        log("Sensor command click");
        setLoading();
        // send sensors commands in sensors dahboard
        if (type == CommandTypes.SENSOR_SWITCH) {
          sendSensorCommand(
            idSensor,
            getCommandByType(CommandTypes.LIGHT),
            req_params,
            onSuccess,
            onError,
            onEnd
          );
          sendSensorCommand(
            idSensor,
            getCommandByType(CommandTypes.SIREN),
            req_params,
            onSuccess,
            onError,
            onEnd
          );
        } else {
          sendSensorCommand(
            idSensor,
            cmd_type,
            req_params,
            onSuccess,
            onError,
            onEnd
          );
        }
      } else if (idPlant !== undefined && idPlant !== null) {
        log("Plant command click");
        setLoading();
        sendPlantCommand(
          idPlant,
          cmd_type,
          req_params,
          onSuccess,
          onError,
          onEnd
        );
      }

      if (waitForNewStates) {
        _onEnd();

        this.customSetState(
          {
            canAutoToggle: false,
            checked: !this.state.checked,
            newChecked: !!this.props.initialChecked,
          },
          () => {
            clearTimeout(this.canAutoToggleTimeout);
            log("Wait 2 minutes from now");
            this.canAutoToggleTimeout = setTimeout(
              () => this.updateCheckedAfterTimeout(),
              120000
            );
          }
        );
      }
    }
  }

  updateCheckedAfterTimeout() {
    log("2 minutes expired");
    const { newChecked } = this.state;

    this.customSetState({
      canAutoToggle: true,
      checked: newChecked,
    });
  }

  getSwitchStyle() {
    const { type, disabled } = this.props;
    let switch_state = this.state.checked
      ? _style[SWITCH_PRE_STYLE_PROP + type]
      : _style[SWITCH_PRE_STYLE_PROP + type + "off"];

    if (
      (type == CommandTypes.SENSOR_SWITCH &&
        (this.state.status_verbose == SensorStatus.DISABLED ||
          this.state.status_verbose == SensorStatus.OFFLINE)) ||
      disabled
    ) {
      switch_state = _style[SWITCH_PRE_STYLE_PROP + type + "disabled"];
    }

    return {
      ..._style.switch,
      ...switch_state,
    };
  }

  renderSwitch() {
    const { type } = this.props;
    const { status_verbose } = this.state;
    let labels = <span>{this.state.checked ? "ON" : "OFF"}</span>;

    // for sensor dashboard
    if (
      type == CommandTypes.SENSOR_SWITCH &&
      (status_verbose == SensorStatus.DISABLED ||
        status_verbose == SensorStatus.OFFLINE)
    ) {
      labels = "OFFLINE";
    }

    return (
      <div>
        <div className={SWITCH_CLASS} style={this.getSwitchStyle()}>
          {this.renderCircle()}
        </div>
        <div style={_style.commandBottom}>{labels}</div>
      </div>
    );
  }

  //---------------------------------------------- circle -----------------------------------------------------------
  getCircleStyle() {
    const { switchButton, type, disabled } = this.props;
    const { checked } = this.state;

    let style = _style.command;

    if (switchButton) {
      const translateX = checked ? this.state.translateX : 0;
      let command_state = checked ? _style[type] : _style.commandOff;
      if (type == CommandTypes.SENSOR_SWITCH) {
        if (
          this.state.status_verbose == SensorStatus.DISABLED ||
          this.state.status_verbose == SensorStatus.OFFLINE ||
          disabled
        ) {
          command_state = _style[type + "disabled"];
        } else {
          command_state = checked ? _style[type] : _style[type + "off"];
        }
      }

      style = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...style,
        transform: `translateX(${translateX}px)`,
        ...command_state,
      };
    }

    return style;
  }

  renderCircle() {
    const { type, switchButton } = this.props;
    const { checked } = this.state;

    return (
      <div className="command" style={this.getCircleStyle()}>
        <Icon
          name={getIconNameByType(type)}
          bar={switchButton && !checked}
          white
        />
      </div>
    );
  }

  // confirm disable status modal
  renderConfirmDisableModal() {
    if (!this.props.confirmDisable) return null;

    const { idSensor, idPlant, type, t } = this.props;
    const title_append =
      idSensor !== undefined && idSensor !== null
        ? " sensor?"
        : idPlant !== undefined && idPlant !== null
        ? " track?"
        : "";

    const confirmTurnOffLabel = idSensor
      ? t("confirm_sensor_turn_off", { id: idSensor })
      : t("confirm_plant_turn_off", { id: idPlant });

    const title =
      type === CommandTypes.LION_KING
        ? confirmTurnOffLabel
        : `Disable${title_append}`;

    return (
      <ConfirmModal
        htmlID={this.idConfirmDisablePopupModal}
        show={this.state.showConfirmModal}
        title={title}
        onConfirmClick={() => this.toggleSwitch()}
        onCancelClick={() => this.onConfirmCancel()}
        onDismiss={() => this.onConfirmCancel()}
      />
    );
  }

  onConfirmCancel() {
    this.customSetState({ showConfirmModal: false });
  }

  renderCommandInfoModal() {
    let status = this.state.checked ? "ON" : "OFF";
    let label = this.props.type;
    if (this.props.type === CommandTypes.IDLE) {
      label = "Sensor";
    } else if (this.props.type === CommandTypes.SENSOR_SWITCH) {
      label = "Signal";
    } else {
      let upper_case = label.charAt(0).toUpperCase() + label.slice(1);
      label = upper_case.split("_").join(" ");
    }

    return (
      <Modal
        id={this.idCommandInfoModal}
        show={this.state.showCommandInfoModal}
        canDismiss={false}
        // onDismiss={() => this.customSetState({showCommandInfoModal: false})}
      >
        <div className="modal-body">
          <p style={_style.commandInfoLabel}>{`${label} ${status}`}</p>
        </div>
      </Modal>
    );
  }

  render() {
    const { mounted, loading, status_verbose } = this.state;

    if (!mounted) return null;

    const { switchButton, href, type } = this.props;

    if (!!href) {
      return (
        <a
          id={this.htmlID}
          href={href}
          style={_style.wrapper}
          disabled={this.props.disabled}
        >
          {this.renderCircle()}
        </a>
      );
    }

    // for sensor dashboard
    const disabled =
      (type == CommandTypes.SENSOR_SWITCH &&
        (status_verbose == SensorStatus.DISABLED ||
          status_verbose == SensorStatus.OFFLINE)) ||
      this.props.disabled;

    return (
      <div>
        <button
          id={this.htmlID}
          disabled={disabled}
          onClick={() => this.onSwitchClick()}
          style={_style.wrapper}
        >
          <Loader loading={loading} />

          {!switchButton ? this.renderCircle() : this.renderSwitch()}
        </button>

        {this.renderCommandInfoModal()}
        {this.renderConfirmDisableModal()}
      </div>
    );
  }
}

function mapStateToProps({ command, currentSensor, plantsSiren }) {
  return { command, currentSensor, plantsSiren };
}

export default connect(mapStateToProps, {
  sendSensorCommand,
  sendPlantCommand,
  fetchSensorStatus,
  updateCurrentSensorWS,
  sensorDetailWSClosed,
})(translate("Command")(Command));

const _style = {
  wrapper: {
    borderRadius: 37,
    border: "none",
    padding: 0,
    backgroundColor: "#fff",
    boxShadow: SWITCH_ACTIVE_BOX_SHADOW_PX + "#fff",
  },
  switch: {
    // width: see style.css,
    // height: see style.css,
    // borderBottomLeftRadius: '100%',
    // borderBottomRightRadius: '100%',
    // borderTopLeftRadius: '100%',
    // borderTopRightRadius: '100%',
    backgroundColor: "#d1d1d1",
    // border: '1px solid #9D9D9D',
    cursor: "pointer",
  },
  command: {
    overflow: "hidden",
    // width: see style.css,
    // height: see style.css,
    borderRadius: "100%",
    // border: '1px solid black',
    margin: 0,
    transitionDuration: "0.2s",
    backgroundColor: colors.secondary,
    cursor: "pointer",
    // padding: see style.css,
    MozBoxShadow: "inset 7px 0 10px rgba(0,0,0,.3)",
    WebkitBoxShadow: "inset 7px 0 10px rgba(0,0,0,.3)",
    boxShadow: "inset 7px 0 10px rgba(0,0,0,.3)",
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
    MsUserSelect: "none",
    userSelect: "none",
  },
  commandOn: {
    backgroundColor: colors.success,
  },
  commandOff: {
    backgroundColor: colors.disabled,
  },
  commandBottom: {
    display: "flex",
    justifyContent: "center",
    marginTop: 2,
  },
  commandInfoLabel: {
    textAlign: "center",
    margin: "1rem 0",
  },
  // TODO define all commands' styles
  [CommandTypes.RESTART]: {},
  [CommandTypes.IDLE]: {
    backgroundColor: colors.success,
  },
  [CommandTypes.LIGHT]: {
    backgroundColor: colors.error,
  },
  [CommandTypes.PLANT_MODE]: {
    backgroundColor: colors.success,
  },
  [CommandTypes.SENSOR_SWITCH]: {
    backgroundColor: colors.error,
  },
  [CommandTypes.SENSOR_SWITCH + "off"]: {
    backgroundColor: colors.disabled,
  },
  [CommandTypes.SENSOR_SWITCH + "disabled"]: {
    backgroundColor: colors.disabledLight,
    boxShadow: SWITCH_ACTIVE_BOX_SHADOW_PX + colors.disabledLight,
  },
  [CommandTypes.SIREN]: {
    backgroundColor: colors.error,
  },
  [CommandTypes.LION_KING]: {
    backgroundColor: colors.success,
  },
  [SWITCH_PRE_STYLE_PROP + "off"]: {
    backgroundColor: colors.disabledLight,
    boxShadow: SWITCH_ACTIVE_BOX_SHADOW_PX + colors.disabledDark,
  },
  [SWITCH_PRE_STYLE_PROP + CommandTypes.RESTART]: {},
  [SWITCH_PRE_STYLE_PROP + CommandTypes.IDLE]: {
    backgroundColor: colors.successLight,
    boxShadow: SWITCH_ACTIVE_BOX_SHADOW_PX + colors.successDark,
  },
  [SWITCH_PRE_STYLE_PROP + CommandTypes.LIGHT]: {
    backgroundColor: colors.errorLight,
    boxShadow: SWITCH_ACTIVE_BOX_SHADOW_PX + colors.errorDark,
  },
  [SWITCH_PRE_STYLE_PROP + CommandTypes.PLANT_MODE]: {
    backgroundColor: colors.successLight,
    boxShadow: SWITCH_ACTIVE_BOX_SHADOW_PX + colors.successDark,
  },
  [SWITCH_PRE_STYLE_PROP + CommandTypes.SENSOR_SWITCH]: {
    backgroundColor: colors.errorLight,
    boxShadow: SWITCH_ACTIVE_BOX_SHADOW_PX + colors.errorDark,
  },
  [SWITCH_PRE_STYLE_PROP + CommandTypes.SENSOR_SWITCH + "off"]: {
    backgroundColor: colors.disabledLight,
    boxShadow: SWITCH_ACTIVE_BOX_SHADOW_PX + colors.disabledDark,
  },
  [SWITCH_PRE_STYLE_PROP + CommandTypes.SENSOR_SWITCH + "disabled"]: {
    backgroundColor: colors.disabledLight,
    boxShadow: SWITCH_ACTIVE_BOX_SHADOW_PX + colors.disabledLight,
  },
  [SWITCH_PRE_STYLE_PROP + CommandTypes.SIREN]: {
    backgroundColor: colors.errorLight,
    boxShadow: SWITCH_ACTIVE_BOX_SHADOW_PX + colors.errorDark,
  },
};
