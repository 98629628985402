import React, { Component } from "react";
import { connect } from "react-redux";
import { translate } from "react-translate";
import { Modal, Icon, Loader } from "../../base_components";
import { types as CommandTypes } from "../../../values/commands";
import { getIconNameByType } from "../../../utils/commands_utils";
import { sendSirenDisablingRequest } from "./actions";

import "../../../../static/css/plantSettingsButton.css";

class PlantSettingsButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      loading: false,
    };

    this.onRequestFinish = this.onRequestFinish.bind(this);
  }

  isEnabled() {
    const { plantId, plantsSiren } = this.props;
    return !plantsSiren[plantId];
  }

  onRequestFinish() {
    this.setState({ loading: false });
  }

  renderIcon() {
    const enabled = this.isEnabled();
    const color = enabled ? "green" : "red";

    return (
      <div
        style={{
          ..._style.settingsRow.iconContainer,
          backgroundColor: color,
        }}
      >
        {this.state.loading ? (
          <Loader loading />
        ) : (
          <Icon
            name={getIconNameByType(CommandTypes.SIREN)}
            bar={!enabled}
            white
            style={_style.settingsRow.icon}
          />
        )}
      </div>
    );
  }

  render() {
    const { plantId, onShow, onShown, onDismiss, t } = this.props;
    const enabled = this.isEnabled();
    const status = enabled ? t("siren_enabled") : t("siren_disabled");
    const command = enabled ? t("disable_command") : t("enable_command");

    const id = `plant-settings-${plantId}`;
    return (
      <>
        <button
          className="plant-settings-button"
          style={_style.button}
          onClick={() => this.setState({ showModal: true })}
        >
          <span className="material-symbols-outlined icon-settings">
            settings
          </span>
        </button>

        <Modal
          show={this.state.showModal}
          id={id}
          onShow={onShow}
          onShown={onShown}
          onHide={() => this.setState({ showModal: false })}
          onDismiss={onDismiss}
        >
          <div className="modal-body">
            <p className="modal-title">{t("settings")}</p>
            <div className="modal-controls">
              <div style={_style.settingsRow.container}>
                <div style={_style.settingsRow.left}>
                  {this.renderIcon()}
                  <label style={_style.settingsRow.label}>{status}</label>
                </div>

                <button
                  className="btn btn-xs"
                  onClick={() => {
                    this.props.sendSirenDisablingRequest(
                      plantId,
                      enabled,
                      this.onRequestFinish,
                      this.onRequestFinish
                    );
                    this.setState({ loading: true });
                  }}
                >
                  {command}
                </button>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-secondary"
              onClick={() => this.setState({ showModal: false })}
            >
              {t("close")}
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

const _style = {
  button: {
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  menu: {},
  settingsRow: {
    container: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    left: {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },
    iconContainer: {
      width: 32,
      height: 32,
      borderRadius: 32,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      overflow: "hidden",
    },
    icon: {
      width: 20,
    },
    label: {
      marginBottom: 0,
    },
  },
};

export default connect(({ plantsSiren }) => ({ plantsSiren }), {
  sendSirenDisablingRequest,
})(translate("PlantSettings")(PlantSettingsButton));
