import React from "react";

const Icon = (props) => {
  const { name, style, className, colored, white, bar, fill } = props;

  if (!name) return null;

  if (name === "power")
    return (
      <span
        className="material-symbols-outlined"
        style={{ fontSize: 40, color: "white", ...style }}
      >
        power_settings_new
      </span>
    );

  if (name === "camera_off")
    return (
      <span
        className="material-symbols-outlined"
        style={{ fontSize: 40, color: "white", ...style }}
      >
        videocam_off
      </span>
    );

  const bar_append = bar ? "_bar" : "";
  const color_append = white ? "_white" : colored ? "_color" : "";
  const fill_append = fill ? "_fill" : "";

  return (
    <img
      src={`/static/assets/icons/icon_${name}${bar_append}${color_append}${fill_append}.svg`}
      className={className}
      style={style}
    />
  );
};

export default Icon;
